import React from 'react'
import { Container } from './module'
import { ReactComponent as ScsLogo } from '@/logo.svg'

export default () => {
  return (
    <Container>
      <ScsLogo />
    </Container>
  )
}