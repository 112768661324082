import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: black;
  overflow: hidden;
  aspect-ratio: 16/9;
`

export const Image = styled.img`
  height: 100%;
  max-height: 100%;
  object-position: center;
  /* aspect-ratio: 2560/1440; */
`