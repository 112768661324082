import { useState } from 'react'
import { backendUrl } from '@/env'
import { useNavigate } from 'react-router-dom'

type Controller = [
  {
    code: string
    error: string | null
    loading: boolean
  },
  {
    handleCodeInput: (value: string) => void
    handleConnectButton: () => void
  }
]

export const useController = (): Controller => {
  const [ code, setCode ] = useState<string>('')
  const [ error, setError ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)

  const navigate = useNavigate()

  const handleCodeInput = (value: string) => {
    if (value.length > 6) return
    
    setCode(value.toUpperCase())
  }

  const handleConnectButton = async () => {
    if (code.length < 6) {
      setError('Code must be 6 characters long')
      return
    }

    setError(null)
    setLoading(true)

    try {
      const res = await fetch(`${backendUrl}/join-connection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          connectionCode: code,
          deviceType: 'receiver',
        })
      })
  
      const body = await res.json()
      
      if (body.status === 'success' ) {
        localStorage.setItem('connection', JSON.stringify({
          connectionId: body.connectionId,
          code: body.connectionCode,
          id: body.id
        }))

        setTimeout(() => {
          setLoading(false)
          location.href = '/'
        }, 1000)
      }
      else if (body.status === 'error') {
        throw new Error(body.message)
      }
      else {
        throw new Error('something went wrong, please try again')
      }
    }
    catch (err: any) {
      console.error(err)
      setLoading(false)
      setError(err.message)
    }
  }

  const state = {
    code,
    error,
    loading
  }
  const actions = {
    handleCodeInput,
    handleConnectButton,
  }

  return [ state, actions ]
}
