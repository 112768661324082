class Connection {
  constructor() {}

  public get isConnected(): boolean {
    let connected = false

    if (localStorage.getItem('connection')) {
      connected = true
    }

    return connected
  }

  public get connectionId(): string | null {
    const connection = localStorage.getItem('connection')

    if (connection) {
      return JSON.parse(connection).connectionId
    }

    return null
  }
}

export default new Connection()