import React, { useEffect, useState } from 'react'
import { useSocket } from '@/utils/socket'
import { Container, Image } from './module'
import { useNavigate } from 'react-router-dom'

const DisplayImage =  () => {
  const [ selectedImage, setSelectedImage ] = useState<string>('')
  const socket = useSocket()
  const navigate = useNavigate()

  useEffect(() => {
    if ( !socket ) {
      navigate('/connect-to-cast')
    }
    else {
      socket.on('set-image-on-screen', (image: string) => {
        setSelectedImage(image)
      })
    }
  }, [])

  return (
    <Container>
      {selectedImage 
        ? <Image src={selectedImage} alt={selectedImage} />
        : (<p style={{ display: 'grid', placeItems: 'center', width: '100%', aspectRatio: '1680/1050', backgroundColor: 'gray', color: 'white', margin: '0'}}>
            Select an image
          </p>
        )
      }
    </Container>
  )
}

export default DisplayImage