import { Theme } from '@/theme'
import React from 'react'
import styled from 'styled-components'

interface Props {
  variant: 'green' | 'standard'
  theme: Theme
}

const Button = styled.button<Props>`
  background-color: ${ 
    ({ variant, theme }) => variant === 'green' 
      ? theme.colors.ctaGreen
      : theme.colors.white 
  };
  color: ${ 
    ({ variant, theme }) => variant === 'green' 
      ? theme.colors.brokenWhite
      : theme.colors.lightBlack 
  };
  padding: 12px;
  border-radius: 50px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
`

export default Button