import React from 'react'
import { ThemeProvider } from 'styled-components'

export const red = '#DB2B39'
export const scsRed = '#DB2B39'
export const white = '#FFFFFF'
export const brokenWhite = '#FAFAFA'
export const grey = '#F4F4F4'
export const darkGrey = '#D1D1D1'
export const ctaGreen = '#689600'
export const black = '#222222'
export const lightBlack = '#646464'

export const theme = {
  colors: {
    red, scsRed, white, brokenWhite, grey, darkGrey, ctaGreen, black, lightBlack
  }
}

export type Theme = typeof theme

interface Props {
  children: React.ReactNode
}

const Provider: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  )
}

export default Provider