import { io } from 'socket.io-client'
import connection from './connection'
import { backendUrl } from '@/env'

export const connectToWs = () => {
  const isConnected = connection.isConnected
  const connectionId = connection.connectionId
  // const socket = io("http://scs-swoosh-backend.eu-4.evennode.com/", { autoConnect: false, extraHeaders: { "connectionId": connectionId } })
  if ( !isConnected ) return null

  const socket = io(backendUrl, { autoConnect: false, extraHeaders: { "connectionId": connectionId as string } })
  socket.auth = { connectionId }
  socket.connect()
  console.log(socket)

  return socket
}

const socket = connectToWs()

export const useSocket = () => {
  return socket
}