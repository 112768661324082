import React, { useEffect, useState } from 'react'
import Router from '@/Router'
import ThemeProvider from './theme'

function App() {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
