import React, { useEffect, useRef } from 'react'
import { Container, LoginBox, ErrorBox } from './module'
import CastedImage from '@/components/casted-image'
import { useController } from './controller'
import Button from '@/components/ui/button'

interface Props {}

const ConnectToCast: React.FC<Props> = () => {
  const [ state, actions ] = useController()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  })

  return (
    <Container>

      <LoginBox>
        <h1>
          Connect to cast
        </h1>

        <p>
          Enter the connection code below, <br></br>
          then click continue button on your tablet device
        </p>

        <input
          type='text'
          ref={ inputRef }
          value={ state.code }
          onChange={ e => actions.handleCodeInput(e.target.value) }
        />

        { state.error && (
          <ErrorBox>
            { state.error }
          </ErrorBox> 
        )}

        <Button variant='green' onClick={ actions.handleConnectButton } disabled={ state.loading }>
          { state.loading ? 'Connecting...' : 'Connect' }
        </Button>
      </LoginBox>

      <CastedImage 
        resolution='2k' 
        imageUrl='dw6bb29fab/images/large/large_ASPEN5_3.jpg'
      />
    </Container>
  )
}

export default ConnectToCast