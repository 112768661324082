import React from 'react'
import { Container } from './module'

import Header from '@/components/header'

export default () => {
  return (
    <Container>
      <Header />
    </Container>
  )
}