import React from 'react'
// import { Container } from './module'

interface Props {
  resolution: '2k' | '4k' | 'full-hd'
  imageUrl: string
}

const CastedImage: React.FC<Props> = ({ resolution, imageUrl }) => {

  const getResolution = (type: 'attributes' | 'string') => {
    let resolution = {}
    let resolutionString = ''

    switch (resolution) {
      case '2k':
        resolutionString = 'sw=2560&sh=1440&sfrm=jpg'
        resolution = { width: '2560px', height: '1440px' }
        break;
      case '4k':
        resolutionString = 'sw=3840&sh=2160&sfrm=jpg'
        resolution = { width: '3840px', height: '2160px' }
        break;
      case 'full-hd':
        resolutionString = 'sw=1920&sh=1080&sfrm=jpg'
        resolution = { width: '1920px', height: '1080px' }
        break;
    }

    return type === 'attributes' ? resolution : resolutionString
  }

  return (
    <img
      src={`https://www.scs.co.uk/dw/image/v2/AARC_PRD/on/demandware.static/-/Sites-master-catalog-scs/default/${imageUrl}?${getResolution('string')}`}
      alt='product-image'
      className='casted-image'
      { ...getResolution('attributes') }
    />
  )
}

export default CastedImage