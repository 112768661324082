import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

import DisplayImage from '@/pages/display-image'
import CreateConnection from '@/pages/create-connection'
import ConnectToCast from '@/pages/connect-to-cast'

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/create-new-connection" element={<CreateConnection />} />
        <Route path="/connect-to-cast" element={<ConnectToCast />} />
        <Route path="/" element={<DisplayImage />} />
      </Routes>
    </Router>
  )
}

export default AppRouter